body.upside {
  transform: rotate(-180deg);
  transition-duration: 2s;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.snowflakes {
  display: none;
}

.upside .snowflakes {
  display: block;
}

.snowflake {
  width: 0.75rem;
  height: 0.75rem;
  filter: blur(2px);
  border-radius: 50%;
  opacity: 0.7;
  background: rgb(255, 255, 255) !important;
}

@keyframes snowflakes-fall {
  0% {
    top: -50%
  }

  100% {
    top: 100%
  }
}

@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px)
  }

  50% {
    transform: translateX(80px)
  }

  100% {
    transform: translateX(0px)
  }
}

.snowflake {
  position: fixed;
  bottom: -50%;
  z-index: 9999;
  cursor: default;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running
}

.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s
}

.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s
}

.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, .5s;
  animation-delay: 6s, .5s
}

.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s
}

.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s
}

.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s
}

.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s
}

.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s
}

.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s
}

.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s
}
