/* You can add global styles to this file, and also import other style files */
@import "theme.scss";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
@import "./app/translator/online-editor/upside-down.scss";

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Inter', sans-serif;
  background-color: #F3F4F6;
}

body.upsideDown {
  filter: none;
}

* {
  box-sizing: border-box;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

#zmmtg-root {
  display: none;
}

.ph-item {
  border: 0;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
}

.ph-picture {
  height: 50px;
  border-radius: 12px;
}