@use '@angular/material' as mat;
/* Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once! */
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$icteam-red-palette : (50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400 : #ef5350,
  500: #f44336,
  600 : #e53935,
  700 : #d32f2f,
  800 : #c62828,
  900: #b71c1c,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400 : white,
    500 : white,
    600 : white,
    700 : white,
    800 : white,
    900 : white));

/* Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/ */
$candy-app-primary: mat.define-palette(mat.$gray-palette);
$candy-app-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);

/* The warn palette is optional (defaults to red). */
$candy-app-warn: mat.define-palette(mat.$red-palette);
$candy-red-primary : mat.define-palette(mat.$red-palette, A400);
$candy-blue-primary : mat.define-palette(mat.$blue-palette, 900);
/* Create the theme object (a Sass map containing all of the palettes). */
$candy-app-theme: mat.define-dark-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
$candy-light-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

/* Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using. */
@include mat.all-legacy-component-themes($candy-app-theme);
@include mat.legacy-button-theme($candy-light-theme);
@include mat.legacy-tabs-theme($candy-light-theme);
@include mat.expansion-theme($candy-light-theme);
@include mat.legacy-select-theme($candy-light-theme);
@include mat.legacy-option-theme($candy-light-theme);
@include mat.legacy-checkbox-theme($candy-light-theme);
@include mat.legacy-autocomplete-theme($candy-light-theme);
@include mat.legacy-paginator-theme($candy-light-theme);
@include mat.legacy-menu-theme($candy-light-theme);
@include mat.legacy-card-theme($candy-light-theme);
@include mat.legacy-dialog-theme($candy-light-theme);
@include mat.legacy-table-theme($candy-light-theme);
@include mat.datepicker-theme($candy-light-theme);
@include mat.legacy-list-theme($candy-light-theme);
@include mat.legacy-radio-theme($candy-light-theme);
@include mat.sidenav-theme($candy-light-theme);
@include mat.bottom-sheet-theme($candy-light-theme);

.light-form {
  @include mat.legacy-form-field-theme($candy-light-theme);
  // @include mat.radio-theme($candy-light-theme);
  // @include mat.button-theme($candy-app-theme);
  // @include mat.table-theme($candy-light-theme);
  @include mat.tree-theme($candy-light-theme);
}

.dark-red-theme {
  $candy-red-theme: mat.define-light-theme($candy-blue-primary, $candy-app-primary);
  @include mat.legacy-form-field-theme($candy-red-theme);
  @include mat.legacy-menu-theme($candy-red-theme);
  @include mat.legacy-option-theme($candy-red-theme);
  @include mat.legacy-button-theme($candy-red-theme);
  // @include mat.radio-theme($candy-light-theme);
  @include mat.legacy-autocomplete-theme($candy-red-theme);
  @include mat.stepper-theme($candy-light-theme);
}
